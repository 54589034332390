import React from 'react';
import * as styles from './modules/buttonSeeSomething.module.scss';
import { navigate } from 'gatsby';

const ButtonSeeSomething = ({ text }) => {
  const redirectTo = () => {
    navigate(`/programme/`);
  };

  return (
    <>
      {text === 'Découvrir nos avis...' ? (
        <a
          href="https://fr.trustpilot.com/review/bemum.co?utm_medium=trustbox&utm_source=MicroReviewCount"
          target="_blank"
          rel="noreferrer"
          className={styles.seeSomething} 
        >
          {text}
        </a>
      ) : (
        <button className={styles.seeSomething} onClick={() => redirectTo()}>
          {text}
        </button>
      )}
    </>
  );
};

export default ButtonSeeSomething;

// extracted by mini-css-extract-plugin
export var baseStyles = "shop-module--baseStyles--9ERQc";
export var container = "shop-module--container--iiByc";
export var containerDescription = "shop-module--containerDescription--la6kH";
export var containerDescriptionMobile = "shop-module--containerDescriptionMobile--i+AaT";
export var containerMobile = "shop-module--containerMobile--UHjGt";
export var containerTitle = "shop-module--containerTitle--siNCz";
export var containerTitleMobile = "shop-module--containerTitleMobile--inaZR";
export var expertise = "shop-module--expertise--evrDV";
export var expertiseMobile = "shop-module--expertiseMobile--8-WWu";
export var image = "shop-module--image--AfG-j";
export var imageMobile = "shop-module--imageMobile--Fj7wP";
export var programmeContainer = "shop-module--programmeContainer--uGgu3";
export var supplementsContainer = "shop-module--supplementsContainer--xz2J0";
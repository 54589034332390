import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import * as styles from '../modules/shop.module.scss';
import { navigate } from 'gatsby';
import Link from '../components/utils/link';
import CartContext from '../context/CartContext';
import DietaryCart from '../components/repeatable/dietaryCart';
import ButtonSeeSomething from '../components/repeatable/buttonSeeSomething';
import HeaderPage from '../components/repeatable/headerPage';

const Shop = () => {
  useContext(CartContext);

  const {
    pageShopYaml: { title, description, seo, choices, see_testimonies, feedback, star, star_empty},
  } = useStaticQuery(graphql`
    query pageShopQuery {
      pageShopYaml {
        title
        description
        star {
          publicURL
        }
        star_empty {
          publicURL
        }
        seo {
          title
          meta
          canonical
        }

        choices {
          id
          image {
            publicURL
          }

          title
          description

          cart
          program_type
        }

        see_testimonies {
          title
        }

        feedback {
          id
          title
          description
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={seo.title} description={seo.meta} canonical={seo.canonical} />
      <div className={styles.baseStyles}>
        <HeaderPage title={title} description={description} />

        <section className={styles.programmeContainer}>
          <div className={styles.supplementsContainer}>
            {choices.map((choice) => (
              <DietaryCart choice={choice} star={star}  starEmpty={star_empty}/>
            ))}
          </div>
          <ButtonSeeSomething text={see_testimonies.title} />
        </section>

        <section className={styles.expertise}>
          <div className={styles.container}>
            <h2 className={styles.containerTitle}>{feedback.title}</h2>
            <p className={styles.containerDescription} dangerouslySetInnerHTML={{ __html: feedback.description }} />
          </div>
          <div className={styles.image}>
            <GatsbyImage image={feedback.image.childImageSharp.gatsbyImageData} alt={feedback.alt} />
          </div>
        </section>

        {/* mobile */}
        <section className={styles.expertiseMobile}>
          <div className={styles.containerMobile}>
            <h2 className={styles.containerTitleMobile}>{feedback.title}</h2>
            <div className={styles.imageMobile}>
              <GatsbyImage image={feedback.image.childImageSharp.gatsbyImageData} alt={feedback.alt} />
            </div>
          </div>
          <p className={styles.containerDescriptionMobile} dangerouslySetInnerHTML={{ __html: feedback.description }} />
        </section>
      </div>
    </Layout>
  );
};

export default Shop;
